*,
html,
body {
  font-family: Vazir;
}

h1,
h2,
h3,
h4 {
  font-family: Vazir;
}
body {
  background: #ffffff !important;
  overflow-x: hidden;
}

.table-hover tbody tr:hover {
  color: #caac00 !important;
}
.bg-dark {
  background-color: #343a4048 !important;
}
.jumbotron {
   background: rgb(195,237,48);
background: linear-gradient(204deg, rgba(195,237,48,1) 36%, rgba(82,237,93,1) 100%); 
}
.btnp {

  color: #191a20!important;
  background: #ffffff !important;
  border-color: rgb(195,237,48) !important;
  border-style: solid;
  border-width: 2px!important;

}
.bg-dark {
  background: #191a20 !important;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.coloredbox {
  width: max-content;
  height: auto;
  border-color: rgb(195, 237, 48);
  border-style: solid;
  border-radius: 35px;
  padding: 1rem;
}

.coloredinbox {
  width: max-content;
  height: auto;
  background-color: rgb(195, 237, 48);
  border-radius: 35px;
  padding: 1rem;
}

.textonimage {
  top: 9rem;
  right: 38%;
}

.textonimage2 {
  top: 7rem;
  right: 25%;
}

.textonimage3 {
  top: 7rem;
  right: 33%;
}

.linesimage {
  width: 100%;
  height: 50rem;
}

.indexmobile {
  display: none;
}

.indeximage {
  display: block;
}

.mobilehead {
  padding: 0;
}

.card-title {
  text-align: center;
}

.footer {
  background: rgb(195, 237, 48);
  background: linear-gradient(
    204deg,
    rgba(195, 237, 48, 1) 36%,
    rgba(82, 237, 93, 1) 100%
  );
  height: 100%;
  padding-top: 2rem;

}

/* Responsive navigation menu (for mobile devices) */
@media screen and (max-width: 990px) {
  .center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  .indeximage {
    display: none;
  }

  .indexmobile {
    display: block;
  }

  .textonimage {
    padding: 0.5rem;
    right: 0;
  }

  .textonimage2 {
    padding: 0.5rem;
    right: 0;
  }

  .textonimage3 {
    padding: 0.5rem;
    right: 0;
  }

  .linesimage {
    width: 150%;
    height: 40%;
  }

  .coloredinbox {
    padding: 1rem;
  }
  .mobilehead {
    margin: 2rem;
  }
}







* {
  direction: rtl;
  text-align: right;
}

.form-check-inline .form-check-input {
  margin-left: 0.3125rem !important;
  margin-right: 0 !important;
}

.dropdown-toggle::after {
  margin-left: 0 !important;
  margin-right: 0.255em;
}

.spinner {
  float: right;
  margin-left: 10px;
}

.close {
  left: 0;
  position: absolute;
}

.operation-button {
  margin-left: 5px;
}

.search-item {
  float: right !important;
  margin: 10px 0 10px 5px !important;
}

.export-button {
  margin-right: 0 !important;
  margin-left: 10px;
}

.icon {
  margin: 20px 20px 20px 5px;
}

.icon:first-child {
  margin-right: 0;
}

.Image-controls-3,
.Image-sliderContainer-4 {
  direction: ltr;
}

.page-item:first-child .page-link {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  margin-left: -1px !important;
}

.page-item:last-child .page-link {
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.form-check-input {
  margin-left: unset !important;
}

.form-check-label[for*="checkbox"] {
  margin-right: 1.25rem;
}

.form-check-inline {
  margin-right: 0 !important;
  margin-left: 0.75rem;
}

.react-tags__suggestions {
  left: unset;
  right: 0;
}

.react-tags__selected-tag:after {
  margin-left: 0 !important;
  margin-right: 8px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
