.sidebar {
  margin: 1.5rem;
  padding: 0;
  width: 200px;
  background: rgb(195, 237, 48);
  background: linear-gradient(
    204deg,
    rgba(195, 237, 48, 1) 36%,
    rgba(82, 237, 93, 1) 100%
  );
  position: fixed;
  height: 87vh;
  overflow: auto;
  padding-top: 5rem;
  border-radius: 25px;
}

.card-deck .card {
  border-color: currentcolor;
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(to right, rgb(195, 237, 48), rgb(82, 237, 93)) 1 /
    1 / 0 stretch;
  border-radius: 25px;
}

.sidebar a {
  text-align: center;
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  color: black;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  background-color: #f3f5fc;
  height: 100%;
  border-radius: 25px;
  padding-right: 1.5rem;
}

.topnavmobile {
  display: none;
}
.topnav {
  display: block;
}

.bg-light {
  background-color: #fff !important;
}

.paddingtop {
  margin-right: 250px;
  padding-top: 6rem;
  width: 78vw;
}

.reportbox {
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  margin: 1rem;
  height: 100%;
  width: 100%;
}

.tradingview {
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  height: 240px;
}

.orderlist {

  overflow: auto;
  display: block;
  height: 240px;
  overflow-y: hidden;
  
}
.tdtable {
  text-align: center;
  width: 120px;
  height: 20px;
  display: inline-block;
  border-top: 0 !important;
}
.thtable {
  text-align: center;
  width: 120px;
  height: 30px;
}

.headsectionorder {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}

.table-hover tbody tr:hover {
  background: white !important;
}

@media screen and (max-width: 900px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    display: none;
    margin-top: 0;
  }
  .sidebar a {
    float: right;
  }
  div.content {
    margin-right: 0;
    padding: 1rem;
  }
  .topnavmobile {
    display: block;
  }
  .topnav {
    display: none;
  }
  .paddingtop {
    margin-right: 0rem;
    padding-top: 0;
    width: 100vw;
  }
  .tdtable {
    text-align: center;
    width: 330px;
    height: 20px;
    display: inline-block;
    border-top: 0 !important;
  }
  .thtable {
    text-align: center;
    width: 330px;
    height: 30px;
  }
  .orderlist {

    overflow: auto;
    display: block;
    height: max-content;
    overflow-y: hidden;
    
  }
  
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    display: none;
    margin-top: 0;
  }
  .sidebar a {
    float: right;
  }
  div.content {
    margin-right: 0;
    padding: 1rem;
  }
  .topnavmobile {
    display: block;
  }
  .topnav {
    display: none;
  }
  .paddingtop {
    margin-right: 0rem;
    padding-top: 0;
    width: 100vw;
  }
  .tdtable {
    text-align: center;
    width: 280px;
    height: 20px;
    display: inline-block;
    border-top: 0 !important;
  }
  .thtable {
    text-align: center;
    width: 280px;
    height: 30px;
  }
  .orderlist {

    overflow: auto;
    display: block;
    height: max-content;
    overflow-y: hidden;
    
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
    display: none;
    padding: 1rem;
  }
  .topnavmobile {
    display: block;
  }
  .topnav {
    display: none;
  }
  .paddingtop {
    margin-right: 0rem;
    padding-top: 0;
    width: 100%;
  }
  .reportbox {
    margin: 2rem;
  }
  .tdtable {
    text-align: center;
    width: 160px;
    height: 20px;
    display: inline-block;
    border-top: 0 !important;
  }
  .thtable {
    text-align: center;
    width: 160px;
    height: 30px;
  }
  .orderlist {

    overflow: auto;
    display: block;
    height: max-content;
    overflow-y: hidden;
    
  }
}
